import { SalesChart } from './SalesChart';
import styles from '../../../styles/Card.module.css';
import { parseFilters } from '@utils/parseFilters';
import useToastContext from '@contexts/ToastContext/hook';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import { CustomHelpTooltip } from './CustomHelpTooltip';
import { getDefaultMessage } from '@utils/getDefaultDashboardTipMessage';
import { findSalesSummary } from '@api/reports';
import {
  SalesByDate,
  SalesSummary,
  SalesSummaryFilters,
} from '@interfaces/reports/salesSummary';
import parseDate from '@utils/parseDate';

type Props = {
  filters: SalesSummaryFilters;
};

export function SalesCharts(props: Props) {
  const { filters } = props;

  const toast = useToastContext();
  const [data, setData] = useState<SalesSummary>(salesSummaryInitialState);
  const [previousPeriodData, setPreviousPeriodData] = useState<SalesSummary>(
    salesSummaryInitialState,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (filters.botConfigIds?.length) handleFetchSales(filters);
    else {
      setData(salesSummaryInitialState);
      setPreviousPeriodData(salesSummaryInitialState);
    }
  }, [filters]);

  async function handleFetchSales(fields: Props['filters']) {
    const fetchData = async () => {
      setIsLoading(true);

      const filters = parseFilters(fields);
      const response = await findSalesSummary(filters);
      setData(response.data);
    };

    fetchData()
      .catch((error) => {
        toast.setContent('Não foi possível carregar os registros', 'error');
        console.error(error);
        setData(salesSummaryInitialState);
      })
      .finally(() => {
        setIsLoading(false);
      });

    const handleFetchPreviousPeriodData = async () => {
      setIsLoading(true);

      const filters = parseFilters(fields);
      const response = await findSalesSummary({
        ...filters,
        startDate: filters.previousPeriodStartDate,
        endDate: filters.previousPeriodEndDate,
      });
      setPreviousPeriodData(response.data);
    };

    handleFetchPreviousPeriodData()
      .catch((error) => {
        toast.setContent('Não foi possível carregar os registros', 'error');
        console.error(error);
        setPreviousPeriodData(salesSummaryInitialState);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const getSalesDefaultMessage = (
    type: any,
    currentTotal: number,
    previousTotal: number,
  ) => {
    return getDefaultMessage(
      type,
      currentTotal,
      previousTotal,
      Math.round(((currentTotal - previousTotal) / previousTotal) * 100),
    );
  };

  return (
    <section>
      <div className="pb-main">
        <Typography variant="h2">Período Atual</Typography>
        <Typography className="text-sm text-lightBlack">
          de {parseDate(filters.startDate, { type: 'date' })} a{' '}
          {parseDate(filters.endDate, { type: 'date' })}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Vendas (quantidade)"
            customHelpTooltip={
              <CustomHelpTooltip
                description={getSalesDefaultMessage(
                  'salesCountInfo',
                  +(data?.salesTotalCount || 0),
                  +(previousPeriodData?.salesTotalCount || 0),
                )}
              />
            }
            profitIndicator={
              <ProfitIndicator
                current={+(data?.salesTotalCount || 0)}
                previous={+(previousPeriodData?.salesTotalCount || 0)}
              />
            }
            isLoading={isLoading}
            label={data?.salesTotalCount || ''}
            chartData={data?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.salesCount,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Vendas (valor)"
            customHelpTooltip={
              <CustomHelpTooltip
                description={getSalesDefaultMessage(
                  'salesValueInfo',
                  +(data?.salesTotalValue || 0),
                  +(previousPeriodData?.salesTotalValue || 0),
                )}
              />
            }
            profitIndicator={
              <ProfitIndicator
                current={+(data?.salesTotalValue || 0)}
                previous={+(previousPeriodData?.salesTotalValue || 0)}
              />
            }
            isLoading={isLoading}
            isMoneyChart
            label={data?.salesTotalValue || ''}
            chartData={data?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.paymentValue,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Reembolsos (quantidade)"
            customHelpTooltip={
              <CustomHelpTooltip
                description={getSalesDefaultMessage(
                  'refundsCountInfo',
                  +(data?.refundTotalCount || 0),
                  +(previousPeriodData?.refundTotalCount || 0),
                )}
              />
            }
            profitIndicator={
              <ProfitIndicator
                invertLogic
                current={+(data?.refundTotalCount || 0)}
                previous={+(previousPeriodData?.refundTotalCount || 0)}
              />
            }
            isLoading={isLoading}
            label={data?.refundTotalCount || ''}
            chartData={data?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.refundCount,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Reembolsos (valor)"
            customHelpTooltip={
              <CustomHelpTooltip
                description={getSalesDefaultMessage(
                  'refundsValueInfo',
                  +(data?.refundTotalValue || 0),
                  +(previousPeriodData?.refundTotalValue || 0),
                )}
              />
            }
            profitIndicator={
              <ProfitIndicator
                invertLogic
                current={+(data?.refundTotalValue || 0)}
                previous={+(previousPeriodData?.refundTotalValue || 0)}
              />
            }
            isLoading={isLoading}
            isMoneyChart
            label={data?.refundTotalValue || ''}
            chartData={data?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.refundValue,
              };
            })}
          />
        </Grid>
      </Grid>
      <div className="py-main">
        <Typography variant="h2">Período Anterior</Typography>
        <Typography className="text-sm text-lightBlack">
          de {parseDate(filters.previousPeriodStartDate, { type: 'date' })} a{' '}
          {parseDate(filters.previousPeriodEndDate, { type: 'date' })}
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Vendas (quantidade)"
            isLoading={isLoading}
            label={previousPeriodData?.salesTotalCount || ''}
            chartData={previousPeriodData?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.salesCount,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Vendas (valor)"
            isLoading={isLoading}
            isMoneyChart
            label={previousPeriodData?.salesTotalValue || ''}
            chartData={previousPeriodData?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.paymentValue,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Reembolsos (quantidade)"
            isLoading={isLoading}
            label={previousPeriodData?.refundTotalCount || ''}
            chartData={previousPeriodData?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.refundCount,
              };
            })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={3}>
          <SalesChart
            title="Reembolsos (valor)"
            isLoading={isLoading}
            isMoneyChart
            label={previousPeriodData?.refundTotalValue || ''}
            chartData={previousPeriodData?.sales?.map((sale: SalesByDate) => {
              return {
                date: sale.date,
                value: +sale.refundValue,
              };
            })}
          />
        </Grid>
      </Grid>
    </section>
  );
}

const ProfitIndicator = ({
  current,
  previous,
  invertLogic = false,
}: {
  invertLogic?: boolean;
  current: number;
  previous: number;
}) => {
  let ratioValue = Math.round(((current - previous) / previous) * 100);
  const arrowGuide = ratioValue;

  if (invertLogic) {
    ratioValue = ratioValue * -1;
  }

  const profitValue = (ratioValue > 1 ? ratioValue : ratioValue * -1) || 0;

  return (
    <Box
      className={`${
        ratioValue > 1
          ? styles.positiveProfitIndicator
          : styles.negativeProfitIndicator
      } ${styles.profitIndicator}
      `}
    >
      {arrowGuide > 1 ? (
        <ArrowDropUpRoundedIcon fontSize="small" />
      ) : (
        <ArrowDropDownRoundedIcon fontSize="small" />
      )}
      <Typography className="text-xs">{`${profitValue}%`}</Typography>
    </Box>
  );
};

const salesSummaryInitialState: SalesSummary = {
  refundTotalCount: '0',
  refundTotalValue: '0',
  sales: [],
  salesTotalCount: '0',
  salesTotalValue: '0',
};

export const FILTERS_LOCAL_STORAGE_KEY = 'selected-filters';

function getFilters() {
  const ls = localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY);
  if (!ls) return {};

  return JSON.parse(ls) || {};
}

export function getSelectedFilters(page: string): Record<string, any> {
  const ls = localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY);
  if (!ls) return {};

  return JSON.parse(ls)[page] || {};
}

export function setSelectedFilters(page: string, obj: Record<string, any>) {
  const filters = getFilters();
  filters[page] = { ...filters[page], ...obj };
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(filters));
}

export function clearSelectedFilters() {
  localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY, '{}');
}

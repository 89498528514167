import { z } from 'zod';
import { UserRole } from './auth';

export const customPeriodRangeSchema = z
  .object({
    startDate: z.date({
      errorMap: () => ({
        message: 'A data de início é obrigatória e deve ter um valor váldo.',
      }),
    }),
    endDate: z.date({
      errorMap: () => ({
        message: 'A data de fim é obrigatória e deve ter um valor váldo.',
      }),
    }),
  })
  .superRefine((data, ctx) => {
    const MAX_INTERVAL_MS = 15778800000; // 6 meses
    if (data.endDate.getTime() - data.startDate.getTime() > MAX_INTERVAL_MS) {
      ctx.addIssue({
        code: 'custom',
        message: 'O intervalo máximo é de 6 meses.',
        path: ['endDate'],
      });
      return;
    }

    if (data.startDate.getTime() > data.endDate.getTime()) {
      ctx.addIssue({
        code: 'custom',
        message: 'A data de início deve ser menor que a data de fim.',
        path: ['endDate'],
      });
      return;
    }
  });
export type CustomPeriodRangeForm = z.infer<typeof customPeriodRangeSchema>;

export type DashboardFilters = {
  botConfigIds: number[];
  startDate: Date;
  endDate: Date;
  previousPeriodStartDate: Date;
  previousPeriodEndDate: Date;
  clientTz: string;
  userRole: UserRole;
};

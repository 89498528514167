import useToastContext from '@contexts/ToastContext/hook';
import { Box, CircularProgress } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import { parseFilters } from '@utils/parseFilters';
import parseMoneyValue from '@utils/parseMoneyValue';
import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styles from '@styles/Card.module.css';
import { findSalesHistory } from '@api/reports';
import {
  SalesHistory,
  SalesHistoryFilters,
} from '@interfaces/reports/salesHistory';

type PayloadProps = {
  payload: SalesHistory;
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: PayloadProps[];
}) => {
  if (active && payload && payload.length) {
    return (
      <Box className="custom-tooltip">
        <Box>
          {payload.map((dataPoint: PayloadProps, idx) => {
            const { payload } = dataPoint;
            const salesValue = parseMoneyValue(payload.salesValue) ?? '';
            const groupProfit = parseMoneyValue(payload.groupProfit) ?? '';
            return (
              <Box
                key={idx}
                style={{
                  display: 'inline-block',
                  padding: 10,
                  backgroundColor: 'rgba(113, 42, 217, 0.8)',
                  borderRadius: '5px',
                }}
              >
                <Typography className="text-white text-xs">{`Ano: ${payload.year}`}</Typography>
                <Typography className="text-white text-xs">{`Mês: ${payload.month}`}</Typography>
                <Typography className="text-white text-xs">{`Valor: ${salesValue}`}</Typography>
                <Typography className="text-white text-xs">{`Quantidade: ${payload.salesCount}`}</Typography>
                <Typography className="text-white text-xs">{`Lucro: ${groupProfit}`}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  return null;
};

type Props = { filters: SalesHistoryFilters };

export function SalesHistoryChart(props: Props) {
  const { filters } = props;

  const toast = useToastContext();
  const [data, setData] = useState<SalesHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    filters.botConfigIds.length
      ? handleFetchSalesHistory(filters)
      : setData([]);
  }, [filters]);

  async function handleFetchSalesHistory(fields: SalesHistoryFilters) {
    setIsLoading(true);
    const fetchData = async () => {
      const filters = parseFilters(fields);
      const response = await findSalesHistory(filters);
      setData(response.data);
    };

    fetchData()
      .catch((error) => {
        toast.setContent('Não foi possível carregar os registros', 'error');
        console.error(error);
        setData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const chartData = data?.map((sale) => {
    return {
      date: `${sale.month}/${sale.year}`,
      salesValue: sale.salesValue,
      year: sale.year,
      month: sale.month,
      groupProfit: sale.groupProfit,
      salesCount: sale.salesCount,
    };
  });
  return (
    <section className={styles.containerHistory}>
      {isLoading ? (
        <Box className="flex justify-center items-center h-[277px]">
          <CircularProgress />
        </Box>
      ) : (
        // Essas duas divs pai são necessárias pro ResponsiveContainer não demorar
        // uma vida inteira pra se redimensionar
        // https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
        <div className="relative w-full h-full">
          <div className="absolute w-full h-full t-0 l-0">
            <ResponsiveContainer>
              <BarChart data={chartData}>
                <CartesianGrid
                  strokeOpacity={1}
                  vertical={false}
                  strokeDasharray="6"
                />
                <XAxis
                  dataKey="date"
                  tick={{
                    fontFamily: 'system-ui',
                    fontWeight: '400',
                    fontSize: 12,
                    fill: '#B6BAC3',
                  }}
                  stroke="#EEEEEE"
                />
                <YAxis
                  type="number"
                  tickFormatter={(label: number) => `R$${label / 1000}k`}
                  dataKey={(data) => parseInt(data.salesValue)}
                  tick={{
                    fontFamily: 'system-ui',
                    fontWeight: '400',
                    fontSize: 12,
                    fill: '#B6BAC3',
                  }}
                  stroke="#EEEEEE"
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="salesValue" fill="#712AD9" barSize={12} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </section>
  );
}

import { InputSelectProps } from './InputSelect';
import useGroupsContext from '@contexts/GroupsContext/hook';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import {
  Chip,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  Select,
} from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import { InputText } from './InputText';
import SearchIcon from '@mui/icons-material/Search';
import VibDataGrid, {
  VibDataGridProps,
} from '@vibTheme/components/VibDataGrid';
import { Button } from '@vibTheme/components/_base/Button';
import CloseIcon from '@mui/icons-material/Close';
import {
  ParsedUserRole,
  UserCommunity,
  UserProduct,
  UserRole,
} from '@interfaces/auth';
import { twMerge } from 'tailwind-merge';
import { styles } from './styles';
import useToastContext from '@contexts/ToastContext/hook';
import { useFirstRender } from '@hooks/useFirstRender';
import {
  getSelectedFilters,
  setSelectedFilters,
} from '@repository/selectedFilters';

type Props = Omit<InputSelectProps, 'options' | 'type'> & {
  userRolesFilter: UserRole[];
  selectedFiltersPage: string;
  onChangeCallback?: (ids: number[]) => void;
};

export const InputCommunities = (props: Props) => {
  const {
    name,
    control,
    onChangeCallback,
    selectedFiltersPage,
    userRolesFilter,
    ...restProps
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { communities: userCommunities } = useGroupsContext();
  const { control: modalControl, watch } = useForm({
    defaultValues: { search: '' },
  });

  const allCommunities = filterCommunitiesByUserRoles(
    userCommunities,
    userRolesFilter,
  );

  const selectedFilters = getSelectedFilters(selectedFiltersPage);
  if (!selectedFilters.botConfigIds)
    setSelectedFilters(selectedFiltersPage, {
      botConfigIds: allCommunities.map((group) => group.id),
    });

  const [communities, setCommunities] =
    useState<UserCommunity[]>(allCommunities);
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<
    UserCommunity['id'][]
  >(getSelectedFilters(selectedFiltersPage).botConfigIds || []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const search = watch('search');
  useEffect(() => {
    if (!search) {
      setCommunities(allCommunities);
      return;
    }

    const results = allCommunities.filter((community) =>
      community.name?.toLowerCase().includes(search.toLowerCase()),
    );
    setCommunities(results);
  }, [search]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={selectedCommunityIds}
      render={({ field }) => {
        const toast = useToastContext();
        const triggerNoneSelectedToast = useCallback(
          (ids: UserProduct['id'][]) => {
            !ids.length &&
              toast.setContent(
                'Você deve selecionar pelo menos uma comunidade!',
                'warning',
              );
          },
          [],
        );

        useEffect(() => {
          triggerNoneSelectedToast(field.value);
          field.onChange(field.value);
        }, []);

        const selectedCommunities = allCommunities.filter((product) =>
          field.value.includes(product.id),
        );

        const onSelectionHandler: VibDataGridProps['onSelectionModelChange'] = (
          ids,
        ) => {
          const incomingIds = ids as number[];
          const currentIds = [...selectedCommunityIds, ...incomingIds];
          const uniqueIds = [...new Set(currentIds)];

          const removedIds = [] as number[];
          userCommunities.forEach(
            (product) =>
              !incomingIds.includes(product.id) && removedIds.push(product.id),
          );

          const newIds = uniqueIds.filter(
            (productId) => !removedIds.includes(productId),
          );
          setSelectedCommunityIds(newIds);
        };

        const onSubmit = (ids: UserProduct['id'][]) => {
          triggerNoneSelectedToast(ids);
          field.onChange(ids);
          onChangeCallback?.(ids);
          setSelectedFilters(selectedFiltersPage, { botConfigIds: ids });
          handleClose();
        };

        const isFirstRender = useFirstRender();
        useEffect(() => {
          if (isFirstRender) return;

          setCommunities(allCommunities);
          const ids = allCommunities.map((group) => group.id);
          setSelectedCommunityIds(ids);
          onSubmit(ids);
        }, [JSON.stringify(userRolesFilter)]);

        return (
          <>
            <FormControl
              variant="outlined"
              className={twMerge(styles, restProps.className)}
              error={restProps.error}
            >
              <InputLabel htmlFor="products">Comunidades</InputLabel>
              <Select
                name="communities"
                label="Comunidades"
                // O fakeValue é usado para forçar o label do Mui ao "estado ativo" (para a borda superior do input)
                value="fakeValue"
                displayEmpty
                open={false}
                MenuProps={{ disableScrollLock: true }}
                onClick={() => setIsOpen(true)}
                renderValue={() => {
                  return (
                    <div className="absolute flex items-center -translate-y-1/2 top-1/2 pr-sm max-w-[calc(100%-4.5rem)]">
                      {!!selectedCommunities.length && (
                        <Chip
                          key={selectedCommunities[0]?.id}
                          label={selectedCommunities[0]?.name}
                        />
                      )}
                      {selectedCommunities.length > 1 && (
                        <span className="text-sm ml-1">
                          +{selectedCommunities.length - 1}
                        </span>
                      )}
                    </div>
                  );
                }}
              >
                <option value="fakeValue" />
              </Select>
              <FormHelperText>{restProps.helperText}</FormHelperText>
            </FormControl>

            <Modal open={isOpen} onClose={handleClose} className="flex p-main">
              <div className="flex flex-col bg-white rounded-default p-main mx-auto w-full sm:w-[640px]">
                <div className="relative">
                  <Typography component="h1">Comunidades</Typography>
                  <IconButton
                    className="absolute top-0 right-0 p-0"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <InputText
                  type="search"
                  name="search"
                  control={modalControl}
                  placeholder="Buscar"
                  InputProps={{
                    endAdornment: <SearchIcon className="text-[#6b7280]" />,
                  }}
                  className="my-main"
                />

                <div className="grow [&_.MuiGrid-root]:h-full">
                  <VibDataGrid
                    columns={columns}
                    rows={parseRows(communities)}
                    checkboxSelection
                    onSelectionModelChange={onSelectionHandler}
                    selectionModel={selectedCommunityIds}
                    disableSelectionOnClick={false}
                    autoHeight={false}
                    className="h-full"
                    keepNonExistentRowsSelected
                  />
                </div>
                <Button
                  className="w-full mt-main"
                  onClick={() => onSubmit(selectedCommunityIds)}
                >
                  Adicionar
                </Button>
              </div>
            </Modal>
          </>
        );
      }}
    />
  );
};

const filterCommunitiesByUserRoles = (
  communities: UserCommunity[],
  userRoles: UserRole[],
) => {
  return communities.filter((community) =>
    userRoles
      .map((userRole) => community.userRoles.includes(userRole))
      .some((val) => val),
  );
};

const columns = [
  { field: 'name', headerName: 'Nome' },
  { field: 'userRoles', headerName: 'Função de usuário' },
];

const parseRows = (rows: UserCommunity[]) =>
  rows.map((userCommunity) => ({
    ...userCommunity,
    userRoles: userCommunity.userRoles
      .map((role) => ParsedUserRole[role])
      .join(', '),
  }));

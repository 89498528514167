import { GroupMetricsChart } from './GroupMetricsChart';
import { parseFilters } from '@utils/parseFilters';
import useToastContext from '@contexts/ToastContext/hook';
import React, { useState, useEffect } from 'react';
import { DashboardFilters } from '@interfaces/dashboard';
import { Grid } from '@mui/material';
import { findNewUsers } from '@api/reports';
import { NewUsers } from '@interfaces/reports/newUsers';
import useGroupsContext from '@contexts/GroupsContext/hook';

type GroupMetricsChartsFilters = Pick<
  DashboardFilters,
  'botConfigIds' | 'startDate' | 'endDate'
>;
type GroupMetricsChartsProps = {
  filters: GroupMetricsChartsFilters;
};

const newUsersInitialState: NewUsers = {
  newSubscribers: 0,
  newUsers: 0,
  totalSubscribers: 0,
  totalUsers: 0,
};

export function GroupMetricsCharts(props: GroupMetricsChartsProps) {
  const { filters } = props;

  const toast = useToastContext();
  const [data, setData] = useState<NewUsers>(newUsersInitialState);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    filters.botConfigIds.length
      ? handleFetchNewUsers(filters)
      : setData(newUsersInitialState);
  }, [filters]);

  const { filterProductIdsByRole } = useGroupsContext();
  async function handleFetchNewUsers(fields: GroupMetricsChartsFilters) {
    const fetchData = async () => {
      setIsLoading(true);

      const filters = parseFilters(fields);
      const response = await findNewUsers({
        ...filters,
        botConfigIds: filterProductIdsByRole(filters.botConfigIds, [
          'OWNER',
          'COPRODUCER',
        ]),
      });
      setData(response.data);
    };

    fetchData()
      .catch((error) => {
        toast.setContent('Não foi possível carregar os registros', 'error');
        console.error(error);
        setData(newUsersInitialState);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <section>
      <Grid container spacing={3} columnSpacing={3}>
        <Grid item xs={12} sm md>
          <GroupMetricsChart
            isLoading={isLoading}
            chartData={{
              newValue: +(data?.newUsers || 0),
              totalValue: +(data?.totalUsers || 0),
            }}
            label={{ new: 'Novos Usuários', total: 'Total Usuários' }}
            title="Usuários"
          />
        </Grid>
        <Grid item xs sm md>
          <GroupMetricsChart
            isLoading={isLoading}
            chartData={{
              newValue: +(data?.newSubscribers || 0),
              totalValue: +(data?.totalSubscribers || 0),
            }}
            label={{ new: 'Novos Assinantes', total: 'Total Assinantes' }}
            title="Assinantes"
          />
        </Grid>
      </Grid>
    </section>
  );
}

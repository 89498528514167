import React from 'react';
import { DashboardFilters } from '@interfaces/dashboard';
import { SalesCharts } from './SalesCharts';
import { GroupMetricsCharts } from './GroupMetricsCharts';
import { Paper } from '@mui/material';
import { Typography } from '@vibTheme/components/_base/Typography';
import styles from '@styles/Card.module.css';
import { SalesHistoryChart } from './SalesHistoryChart';

type Props = {
  dashBoardFilters: DashboardFilters;
};

export function DashboardCard(props: Props) {
  const {
    botConfigIds,
    startDate,
    endDate,
    previousPeriodStartDate,
    previousPeriodEndDate,
    clientTz,
    userRole,
  } = props.dashBoardFilters;

  return (
    <section>
      <SalesCharts
        filters={{
          botConfigIds,
          startDate,
          endDate,
          previousPeriodStartDate,
          previousPeriodEndDate,
          clientTz,
          userRoles: [userRole],
        }}
      />
      <Paper className={styles.gridHistory}>
        <Typography component="h3" className="p-main">
          Histórico de Vendas
        </Typography>
        <SalesHistoryChart
          filters={{
            botConfigIds,
            clientTz,
            userRoles: [userRole],
          }}
        />
      </Paper>
      {userRole !== 'AFFILIATED' && (
        <GroupMetricsCharts
          filters={{
            botConfigIds,
            startDate,
            endDate,
          }}
        />
      )}
    </section>
  );
}
